import { Link } from "gatsby"
import React from "react"
import { Parallax } from "react-parallax"
import bgImage from "../assets/images/Shopify-Top.jpg"

function Test() {
  return (
    <>
      <section>
        <Parallax
          bgImage={bgImage}
          bgImageAlt="shopify"
          strength={400}
          blur={{ min: 0, max: 1 }}
        >
          <div className="parallax-box">
            <div className="parallax-info">
              <h1>オンラインストア構築</h1>
              <h2>Shopifyパートナーによるストア構築</h2>
              <p>
                Shopify
                JapanBC認定パートナーがあなたのお店のオンラインストア作りを支援します。
                <br />
                最短で2週間ほどでオンラインストアをオープンすることができます。
                <br />
                <span>(写真素材・ロゴ文章などがすでにお揃いの場合)</span>
              </p>
              <div>
                <Link to="/shopify/about-shopify" className="btn-primary">
                  Shopifyについて{" "}
                </Link>
              </div>
            </div>
          </div>
        </Parallax>
      </section>
    </>
  )
}

export default Test
